@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

a {
    font-size: 22px;
    color: #009688;
    margin-left: 0.2vw;
    text-decoration: none;
}

img {
    width: 2vw;
} 